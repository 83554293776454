

@font-face {
  font-family: "Baloo2-Bold";
  src: local("Baloo2-Bold"),
   url("./fonts/Baloo2-Bold.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  }

@font-face {
  font-family: "Baloo2-Regular";
  src: local("Baloo2-Regular"),
   url("./fonts/Baloo2-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  }

@font-face {
  font-family: "Baloo2-ExtraBold";
  src: local("Baloo2-ExtraBold"),
   url("./fonts/Baloo2-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  }

@font-face {
  font-family: "Baloo2-Medium";
  src: local("Baloo2-Medium"),
   url("./fonts/Baloo2-Medium.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  }



@font-face {
  font-family: "Baloo2-SemiBold";
  src: local("Baloo2-SemiBold"),
   url("./fonts/Baloo2-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  }
body {
  margin: 0;
  font-family: 'Baloo2-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}